const contactData = {
  name: 'Pokolm® Frästechnik GmbH & Co. KG',
  mail: 'info@pokolm.de',
  website: 'www.pokolm.com',
  phone: '+49 5247 9361 0',
  fax: '+49 5247 9361 99',
  address: {
    street: 'Adam-Opel-Straße 5',
    zip: '33428',
    city: 'Harsewinkel',
    region: 'NRW',
    country: 'Germany',
  },
  mapsUrl: '',
}

export default contactData
